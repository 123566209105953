import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../Extra/Firebase";
import Logo from "../../Images/Logo.webp";
import "../../Styles/Navbar.css";
import '../../Styles/Home/Sec-3.css';
import '../../Styles/Shop/Shop.css';
import { FaBars, FaTimes, FaShoppingBag, FaRegUser } from "react-icons/fa";
import Loading from "../Extra/Loading";
import '../../Styles/Shop/ChooseCategory.css';

const ChooseCategory = () => {
  const [user, setUser] = useState(null);
  const [cartCount, setCartCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const nav = useNavigate();
  const navRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    document.title = 'john rona - Categories'

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user || null);
    });

    return () => unsubscribe();
  }, [pathname]);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const CheckUserStatusToLogin = () => {
    if (user) {
      nav(`/Profile/${user.uid}`);
    } else {
      nav('/Login');
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesCollection = await db
          .collection("Masof")
          .doc(process.env.REACT_APP_DATABASE_MASOF)
          .collection("Websites")
          .doc(process.env.REACT_APP_DATABASE_PROID)
          .collection("PCategory")
          .get();

        const categoriesList = await Promise.all(categoriesCollection.docs.map(async (categoryDoc) => {
          const categoryData = categoryDoc.data();
          const subCategoriesCollection = await categoryDoc.ref.collection('SubCategories').get();
          const subCategoriesList = subCategoriesCollection.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));

          return {
            id: categoryDoc.id,
            name: categoryData.name,
            subCategories: subCategoriesList,
          };
        }));

        setCategories(categoriesList);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
    updateCartCount();
  }, []);

  const updateCartCount = () => {
    const existingItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartCount(existingItems.length);
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(selectedCategory === categoryId ? null : categoryId);
  };

  const handleSubCategoryClick = (subCategoryId) => {
    nav(`/Shop?filter=${selectedCategory}+-+${subCategoryId}`);
  };

  const handleViewAllSubCategories = () => {
    nav(`/Shop?filter=${selectedCategory}`);
  };

  const selectedCategoryData = categories.find(category => category.id === selectedCategory);

  return (
    <React.Fragment>
      <header>
        <a href="/" style={{ width: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={Logo}
            alt="header logo"
            onContextMenu={(e) => e.preventDefault()}
            onDragStart={(e) => e.preventDefault()}
          />
        </a>
        <nav className="navbar" ref={navRef}>
          <div className="innav">
            <img
              src={Logo}
              alt="header logo"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
            />
            <button onClick={showNavbar} className="navbtn navhide">
              <FaTimes color="#3cba99" />
            </button>
          </div>
          <button onClick={() => nav("/Shop")}>Shop</button>
          <button onClick={() => nav("/ChooseCategory")}>Categories</button>
          <button onClick={() => nav("/Contact-us")}>Contact us</button>
          {/*<button style={{ width: "30px" }} onClick={CheckUserStatusToLogin}>
            <FaRegUser />
          </button>*/}
          <button onClick={() => nav("/Cart")} style={{ position: "relative", width: "30px" }}>
            <FaShoppingBag />
            {cartCount > 0 && (
              <span className="cart-count">{cartCount}</span>
            )}
          </button>
        </nav>
        <button onClick={showNavbar} className="navbtn navshow">
          <FaBars color="#3cba99" />
        </button>
      </header>

      <div className="choose-category">
        <h1 className="category-title">Choose Category</h1>

        <div className="category-container">
          <button onClick={() => nav('/Shop')} className="view-all-products-button">
            View all products
          </button>
          {categories.length > 0 ? (
            <>
              {categories.map((category) => (
                <div key={category.id} className="category-item">
                  <button
                    className={`category-button ${selectedCategory === category.id ? 'selected' : ''}`}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.name}
                  </button>
                  {selectedCategory === category.id && (
                    <div className="subcategories-container">
                      <button
                        className="view-all-button"
                        onClick={handleViewAllSubCategories}
                      >
                        View all
                      </button>
                      {category.subCategories.map((subCategory) => (
                        <button
                          key={subCategory.id}
                          className="subcategory-button"
                          onClick={() => handleSubCategoryClick(subCategory.id)}
                        >
                          {subCategory.name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      
    </React.Fragment>
  );
};

export default ChooseCategory;
