import React, { useState, useEffect } from "react";
import '../../../Styles/Extra/Popups/MainPopup.css';
import { Fade } from "react-reveal";
import { FaTimes } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import ChooseCountry from '../Popups/ChooseCountry';

const MainPopup = (props) => {
  const [isPopupVisible, setIsPopupVisible] = useState(true);

  const closePopup = () => {
    setIsPopupVisible(false);
    localStorage.setItem('popupVisible', false);
  };

  const openPopup = () => {
    setIsPopupVisible(true);
    localStorage.setItem('popupVisible', true);
  };

  useEffect(() => {
    const popupVisible = localStorage.getItem('popupVisible');
    if (popupVisible === null) {
      setIsPopupVisible(true);
    } else {
      setIsPopupVisible(JSON.parse(popupVisible));
    }
  }, []);

  return isPopupVisible ? (
    <div className="popup">
      <Fade bottom>
        <div className="popup-inner">
          <button className="close-btn" onClick={closePopup}>
            <FaTimes size={25} color="#3cba99" />
          </button>

          <h1 className="ht">Choose your country</h1>

          <div className="images">
            <BiWorld size={55} color="#3cba99" />
          </div>
          
          <ChooseCountry fun={closePopup} />

          {props.children}
          <p style={{color: '#fff'}}>Please add Products to the cart to (Place your order!)</p>
          
        </div>
      </Fade>
    </div>
  ) : null;
};

export default MainPopup;
