import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../Extra/Firebase";
import Loading from "../Extra/Loading";
import Logo from "../../Images/Logo.webp";
import "../../Styles/Navbar.css";
import { FaBars, FaTimes, FaShoppingBag, FaRegUser } from "react-icons/fa";

const Profile = () => {

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const nav = useNavigate();
    const navRef = useRef();
    const { pathname } = useLocation();

    useEffect(() => {

          window.scrollTo(0, 0);
          
          const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
              setUser(user);
              setLoading(false);
            } else {
              setUser(null);
            }
          });

      return () => unsubscribe();
    }, [pathname]);

    const showNavbar = () => {
      navRef.current.classList.toggle("responsive_nav");
    };

    const CheckUserStatusToLogin = () => {
      if (user) {
        nav(`/Profile/${user.uid}`);
      } else {
        nav('/Login');
      }
    };

    if (loading) return <Loading />;

    return (
        <React.Fragment>

            <header>
                <img
                  src={Logo}
                  alt="header logo"
                  onContextMenu={(e) => e.preventDefault()}
                  onDragStart={(e) => e.preventDefault()}
                />
        
                <nav className="navbar" ref={navRef}>
                  <div className="innav">
                    <img
                      src={Logo}
                      alt="header logo"
                      onContextMenu={(e) => e.preventDefault()}
                      onDragStart={(e) => e.preventDefault()}
                    />
                    <button onClick={showNavbar} className="navbtn navhide">
                      <FaTimes color="#3cba99" />
                    </button>
                  </div>
                  <button onClick={()=>nav("/Shop")}>Shop</button>
                  <button>About us</button>
                  <button>Contact us</button>
                  <button style={{ width: "30px" }} onClick={CheckUserStatusToLogin}>
                    <FaRegUser />
                  </button>
                  <button onClick={()=>nav("/Cart")} style={{ width: "30px" }}>
                    <FaShoppingBag />
                  </button>
                </nav>
                <button onClick={showNavbar} className="navbtn navshow">
                  <FaBars color="#3cba99" />
                </button>
            </header>

        </React.Fragment>
    )

};

export default Profile;