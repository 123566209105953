import React from "react";
import '../../Styles/Extra/Loading.css'

const Loading = () => {

    return(
        <React.Fragment>

            <div className="MainLoading">

                <div class="container">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>

            </div>

        </React.Fragment>
    )
}

export default Loading