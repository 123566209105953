import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../Extra/Firebase";
import Loading from "../Extra/Loading";
import Logo from "../../Images/Logo.webp";
import "../../Styles/Navbar.css";
import "../../Styles/Shop/ProductView.css";
import { FaBars, FaTimes, FaShoppingBag, FaRegUser, FaPlus } from "react-icons/fa";

const ProductView = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const [clickedButtons, setClickedButtons] = useState({});
  const [cartCount, setCartCount] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const nav = useNavigate();
  const navRef = useRef();
  const { pathname } = useLocation();
  const { productId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    const fetchProducts = async () => {
      try {
        const productsSnapshot = await db
          .collection("Masof")
          .doc(process.env.REACT_APP_DATABASE_MASOF)
          .collection("Websites")
          .doc(process.env.REACT_APP_DATABASE_PROID)
          .collection("Products")
          .get();

        const productsData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setProductsData(productsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();

    return () => unsubscribe();
  }, [pathname]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productDoc = await db
          .collection("Masof")
          .doc(process.env.REACT_APP_DATABASE_MASOF)
          .collection("Websites")
          .doc(process.env.REACT_APP_DATABASE_PROID)
          .collection("Products")
          .doc(productId)
          .get();

        if (productDoc.exists) {
          const productData = { id: productDoc.id, ...productDoc.data() };
          setProduct(productData);
          setMainImage(productData.Images[0]);
          document.title = `${productData.Name}`

        } else {
          console.error("Product not found");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  useEffect(() => {
    const existingItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartCount(existingItems.length);
  }, []);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const CheckUserStatusToLogin = () => {
    if (user) {
      nav(`/Profile/${user.uid}`);
    } else {
      nav('/Login');
    }
  };

  const handleImageClick = (image) => {
    setMainImage(image);
  };

  const addToLocalStorage = (productId, quantity = 1) => {
    try {
      let existingItems = JSON.parse(localStorage.getItem('cart')) || [];
  
      const existingItemIndex = existingItems.findIndex(item => item.productId === productId);
  
      if (existingItemIndex !== -1) {
        existingItems[existingItemIndex].quantity += quantity;
      } else {
        existingItems.push({ productId, quantity });
      }
  
      localStorage.setItem('cart', JSON.stringify(existingItems));
      setCartCount(existingItems.length);
      console.log(`Product with ID ${productId} added to cart with quantity ${quantity}!`);
    } catch (error) {
      console.error('Error updating cart in localStorage:', error);
    }
  };
  

  const handleAddClick = (productId, quantity) => {
    addToLocalStorage(productId, quantity);
    setClickedButtons((prev) => ({ ...prev, [productId]: true }));

    setTimeout(() => {
      setClickedButtons((prev) => ({ ...prev, [productId]: false }));
    }, 2000);
  };

  const getPrice = () => {
    const selectedCountry = JSON.parse(localStorage.getItem('selectedCountry'));
    if (selectedCountry?.value === 'IL') {
      return product?.IL;
    }
    return product?.US;
  };

  const getCurrencySymbol = () => {
    const selectedCountry = JSON.parse(localStorage.getItem('selectedCountry'));
    if (selectedCountry?.value === 'IL') {
      return '₪';
    }
    return '$';
  };
  
  
  if (loading) return <Loading />;

  return (
    <React.Fragment>
       <header>
       <a href="/" style={{width: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img
          src={Logo}
          alt="header logo"
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
        />
        </a>

        <nav className="navbar" ref={navRef}>
          <div className="innav">
            <img
              src={Logo}
              alt="header logo"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
            />
            <button onClick={showNavbar} className="navbtn navhide">
              <FaTimes color="#3cba99" />
            </button>
          </div>
          <button onClick={() => nav("/Shop")}>Shop</button>
          <button onClick={() => nav("/ChooseCategory")}>Categories</button>
          <button onClick={() => nav("/Contact-us")}>Contact us</button>
          {/*<button style={{ width: "30px" }} onClick={CheckUserStatusToLogin}>
            <FaRegUser />
          </button>*/}
          <button onClick={() => nav("/Cart")} style={{ position: "relative", width: "30px" }}>
            <FaShoppingBag />
            {cartCount > 0 && (
              <span className="cart-count">{cartCount}</span>
            )}
          </button>
        </nav>
        <button onClick={showNavbar} className="navbtn navshow">
          <FaBars color="#3cba99" />
        </button>
      </header>


      <section className="ProductView-1">
        <div className="View1-Images">
          <img className="main-image" src={mainImage} alt={product?.Name} />
          <div className="thumbnail-images">
            {product?.Images?.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${product.Name} ${index + 1}`}
                onClick={() => handleImageClick(image)}
                className={image === mainImage ? 'active' : ''}
              />
            ))}
          </div>
        </div>
        
        <div className="View2-Content">
          <h1>{product?.Name}</h1>
          <p>{product?.Description}</p>
         {/*<p>Weight: {product?.Weight}</p>
          <p>Height: {product?.Height}</p>
          <p>Width: {product?.Width}</p> */}
          
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
            min="1"
            placeholder="1"
          />
          <div className="product-price">
            {/* <strong>Price: </strong>{getCurrencySymbol()}{getPrice()} */}
          </div>
          <p style={{color: 'red'}}>Please add Products to the cart to (Place your order!)</p>
          <button onClick={() => handleAddClick(product?.id, quantity)}>
            {clickedButtons[product?.id] ? '✓' : 'Add to cart'}
          </button>
        </div>
      </section>

      <section className="MoreProducts">
        <h1>More of our products</h1>
        <div className="MoreProducts-fetch">
          <section className="Sec-3">
            <div className="Sec-3-display">
              {productsData.slice(0, 4).map((product, index) => (
                <div key={index} className="product-card">
                  <img src={product.Images} alt={product.name} />
                  <h3>{product.Name}</h3>
                <div className="Procardb">
                    <button onClick={() => nav(`/Shop/${product.id}`)}>
                      View Details
                    </button>

                    <button
                      style={{ width: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}
                      onClick={() => handleAddClick(product.id, quantity)}
                    >
                      {clickedButtons[product.id] ? '✓' : <FaPlus />}
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button onClick={() => { nav('/Shop') }}>View More</button>
          </section>
        </div>
      </section>
      <section className="Sec-4">

        <img src={Logo} alt="Logo"/>

        <p>Copyright 2024 - johnrona.com</p>
      </section>
    </React.Fragment>
  );
};

export default ProductView;
