import React, { useState, useEffect } from "react";
import '../../Styles/Auth/Login.css';
import Logo from '../../Images/Logo.webp';
import Loading from "../Extra/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, googleProvider, db } from '../Extra/Firebase';

const Login = () => {
    
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const nav = useNavigate();

    setTimeout(() => {
        setLoading(false);
    }, 1000);

    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    const handleLogin = async () => {
        try {
            await auth.signInWithEmailAndPassword(email, password);
            nav("/");
        } catch (error) {
            console.error("Error signing in with email and password: ", error);
            alert(error.message);
        }
    };

    const signInWithGoogle = async () => {
        try {
            const result = await auth.signInWithPopup(googleProvider);
            const user = result.user;

            const userDoc = await db.collection("Masof").doc(process.env.REACT_APP_DATABASE_MASOF).collection("Websites").doc(process.env.REACT_APP_DATABASE_PROID).collection("Users").doc(user.uid).get();
            if (!userDoc.exists) {
                
                await db.collection("Masof").doc(process.env.REACT_APP_DATABASE_MASOF).collection("Websites").doc(process.env.REACT_APP_DATABASE_PROID).collection("Users").doc(user.uid).set({
                    firstName: user.displayName.split(" ")[0],
                    lastName: user.displayName.split(" ").slice(1).join(" "),
                    email: user.email
                });
            }

            nav("/");
        } catch (error) {
            console.error("Error signing in with Google: ", error);
        }
    };

    if (loading) return (<Loading />);

    return (
        <React.Fragment>
            <div className="MainLogin">
                <div className="Block-1">
                    <div>
                        <img src={Logo} alt="Logo" onContextMenu={(e) => { e.preventDefault(); }} onDragStart={(e) => { e.preventDefault(); }} />
                        <h1>Welcome Back</h1>
                        <p>Please Login to your account to complete your action</p>
                    </div>
                </div>
                <div className="Block-2">
                    <div className="inBlock2">
                        <h1>Login</h1>
                        <div className="Inputs">
                            <input 
                                placeholder="Email" 
                                type="email" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required 
                            />
                            <input 
                                placeholder="Password" 
                                type="password" 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required 
                            />
                            <button onClick={handleLogin}>Login</button>
                            <button onClick={signInWithGoogle} className="google-signin-button">Sign in with Google</button>
                            <p style={{width: '350px', textAlign: 'center'}}>By clicking Sign in with Google, you agree to our Terms of Service and Privacy Policy</p>
                            <p>Don’t have an account? <a href="/#/Signup">SIGNUP!</a></p>
                        </div>
                        <a href="/#/Terms">Terms & Policy</a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;