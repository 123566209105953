import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db, auth } from "../Extra/Firebase";
import Loading from "../Extra/Loading";
import '../../Styles/Extra/Success.css';
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { onAuthStateChanged } from "firebase/auth";

const Success = () => {
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const generateOrderNumber = () => {
    const orderNumber = Math.floor(100000000 + Math.random() * 900000000);
    return orderNumber;
  };
  

  const orderNumber = generateOrderNumber();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("Token");

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    if (token) {
      handlePaymentSuccess(token);
    } else {
      navigate("/");
    }

    return () => unsubscribe();
  }, [location.search, navigate]);

  const handlePaymentSuccess = async (token) => {
    try {
      const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
      const userData = JSON.parse(localStorage.getItem("userData")) || {};
      const country = JSON.parse(localStorage.getItem("selectedCountry")) || {};
      const Totalprice = JSON.parse(localStorage.getItem("totalprice")) || {};

      if (cartItems.length === 0) {
        setPaymentStatus("Error: Cart is empty.");
        return;
      }

      const collectionPath = `Masof/${process.env.REACT_APP_DATABASE_MASOF}/Websites/${process.env.REACT_APP_DATABASE_PROID}/Orders/${token}`;

      const customerData = { ...userData, country, uid: user ? user.uid : "guest" };

      await setDoc(doc(db, collectionPath), {
        orderNumber: orderNumber,
        items: cartItems,
        paymentStatus: "Completed",
        createdAt: new Date(),
        customer: customerData,
        Totalprice: Totalprice
      });

      setPaymentStatus("Payment Successful!");

      localStorage.removeItem("cart");
      localStorage.removeItem("userData");
      localStorage.removeItem("totalprice");

    } catch (error) {
      console.error("Error processing payment:", error);
      setPaymentStatus("Error processing payment.");
    } finally {
      setLoading(false);
    }
  };

  const Btn = () => {
    navigate("/Shop");
  };

  if (loading) return <Loading />;

  return (
    <div className="success-container">
      {paymentStatus === "Error: Cart is empty." ? (
        <div className="error-message">
          <FaExclamationTriangle size={100} color="#e74c3c" />
          <h1>{paymentStatus}</h1>
        </div>
      ) : (
        <div className="success-message">
          <h1>{paymentStatus}</h1>
          <FaCheck size={100} color="#3cba99" />
        </div>
      )}
      <button onClick={() => Btn()}>Continue Shopping</button>
    </div>
  );
};

export default Success;