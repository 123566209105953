import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Loading from "./Components/Extra/Loading";
import Login from "./Components/Auth/Login";
import Signup from "./Components/Auth/Signup";
import Profile from "./Components/Auth/Profile";
import Shop from "./Components/Shop/Shop";
import Cart from "./Components/Shop/Cart";
import ProductView from "./Components/Shop/ProductView";
import Success from "./Components/API/Success";
import ChooseCategory from "./Components/Shop/ChooseCategory";
import Contactus from "./Components/Extra/Contactus";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Loading" element={<Loading />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/Signup" element={<Signup />} />
      <Route path="/Profile/:userId" element={<Profile />} />
      <Route path="/Shop" element={<Shop />} />
      <Route path="/ChooseCategory" element={<ChooseCategory />} />
      <Route path="/Shop/:productId" element={<ProductView />} />
      <Route path="/Cart" element={<Cart />} />
      <Route path="/Success" element={<Success />} />
      <Route path="/Contact-us" element={<Contactus />} />
    </Routes>
  );
}

export default App;
