import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../Extra/Firebase";
import Loading from "../Extra/Loading";
import Logo from "../../Images/Logo.webp";
import '../../Styles/Extra/Contactus.css'
import { FaBars, FaTimes, FaShoppingBag, FaRegUser, FaPlus } from "react-icons/fa";
import emailjs from '@emailjs/browser'

const Contactus = () => {

    const [cartCount, setCartCount] = useState(0);
    const nav = useNavigate();
    const navRef = useRef();
    const { pathname } = useLocation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        country: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_lkc7uij', 'template_ywqeyla', e.target, 'CFO_5PB_GsV0yO2oc')
            .then((result) => {
                console.log(result.text);
                alert("Message sent successfully!");
            }, (error) => {
                console.log(error.text);
                alert("Failed to send the message, please try again.");
            });

        setFormData({
            name: '',
            email: '',
            phone: '',
            country: '',
            message: ''
        });
    };

  useEffect(() => {
    window.scrollTo(0, 0);

    document.title = 'john rona - Contact us'

  }, [pathname]);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  useEffect(() => {

    

    updateCartCount();
  }, []);

  const updateCartCount = () => {
    const existingItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartCount(existingItems.length);
  };


    return (
        <React.Fragment>
        <header>
        <a href="/" style={{ width: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={Logo}
            alt="header logo"
            onContextMenu={(e) => e.preventDefault()}
            onDragStart={(e) => e.preventDefault()}
          />
        </a>

        <nav className="navbar" ref={navRef}>
          <div className="innav">
            <img
              src={Logo}
              alt="header logo"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
            />
            <button onClick={showNavbar} className="navbtn navhide">
              <FaTimes color="#3cba99" />
            </button>
          </div>
          <button onClick={() => nav("/Shop")}>Shop</button>
          <button onClick={() => nav("/ChooseCategory")}>Categories</button>
          <button onClick={() => nav("/Contact-us")}>Contact us</button>
          {/*<button style={{ width: "30px" }} onClick={CheckUserStatusToLogin}>
            <FaRegUser />
          </button>*/}
          <button onClick={() => nav("/Cart")} style={{ position: "relative", width: "30px" }}>
            <FaShoppingBag />
            {cartCount > 0 && (
              <span className="cart-count">{cartCount}</span>
            )}
          </button>
        </nav>
        <button onClick={showNavbar} className="navbtn navshow">
          <FaBars color="#3cba99" />
        </button>
      </header>
        <div className="contact-form-container">
            <h1>Contact Us</h1>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="country">Country</label>
                    <input
                        type="text"
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <button type="submit">Send Message</button>
            </form>

            <p style={{marginTop: '50px'}}>Support Team Information :</p>
            <p>Email: support@johnrona.com</p>
        </div>
        </React.Fragment>
    );
}

export default Contactus;
