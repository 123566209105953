import React, { useState, useEffect } from "react";
import '../../Styles/Auth/Signup.css';
import Logo from '../../Images/Logo.webp';
import Loading from "../Extra/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import { db, auth, googleProvider } from '../Extra/Firebase';

const Signup = () => {
    
    const [loading, setLoading] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const nav = useNavigate();
    const { pathname } = useLocation();

    const setUserProfile = async (user, firstName, lastName, email) => {
        await db.collection("Masof").doc(process.env.REACT_APP_DATABASE_MASOF).collection("Websites").doc(process.env.REACT_APP_DATABASE_PROID).collection("Users").doc(user.uid).set({
            firstName,
            lastName,
            email
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, [pathname]);

    const handleSignup = async () => {
        if (!firstName || !lastName || !email || !password) {
            alert("Please fill in all the fields.");
            return;
        }

        if (!acceptTerms) {
            alert("You must accept the terms and policy to create an account.");
            return;
        }

        try {
            const userCredential = await auth.createUserWithEmailAndPassword(email, password);
            const user = userCredential.user;

            await setUserProfile(user, firstName, lastName, email);

            nav("/");
        } catch (error) {
            console.error("Error signing up with email and password: ", error);
            alert(error.message);
        }
    };

    const signInWithGoogle = async () => {
        try {
            const result = await auth.signInWithPopup(googleProvider);
            const user = result.user;

            const userProfile = user.providerData[0];
            const userNameParts = userProfile.displayName.split(" ");
            const googleFirstName = userNameParts[0];
            const googleLastName = userNameParts.slice(1).join(" ");
            const googleEmail = userProfile.email;

            await setUserProfile(user, googleFirstName, googleLastName, googleEmail);

            nav("/");
        } catch (error) {
            console.error("Error signing in with Google: ", error);
            alert(error.message);
        }
    };

    if (loading) return <Loading />;

    return (
        <React.Fragment>
            <div className="MainLogin2">
                <div className="Block-1">
                    <div>
                        <img src={Logo} alt="Logo" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()} />
                        <h1>Create an account</h1>
                        <p>Please fill your information to be a part of John Rona family!</p>
                    </div>
                </div>

                <div className="Block-2">
                    <div className="inBlock2">
                        <h1>Signup</h1>
                        <div className="Inputs">
                            <input
                                placeholder="First name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                            <input
                                placeholder="Last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />
                            <input
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <input
                                placeholder="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <div className="check" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '10px' }}>
                                <input
                                    style={{ width: '20px', height: '20px' }}
                                    type="checkbox"
                                    checked={acceptTerms}
                                    onChange={(e) => setAcceptTerms(e.target.checked)}
                                />
                                <a href="/#/Terms" style={{ fontSize: '15px' }}>I accept the Terms and Policy</a>
                            </div>
                            <button onClick={handleSignup}>Create account</button>
                            <button onClick={signInWithGoogle} className="google-signin-button">Sign in with Google</button>
                            <p style={{ width: '350px', textAlign: 'center' }}>
                                By clicking Sign in with Google, you agree to our Terms of Service and Privacy Policy
                            </p>
                            <p>Already a member? <a href="/#/Login">LOGIN!</a></p>
                        </div>
                        <a href="/#/Terms">Terms & Policy</a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Signup;
