import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../Extra/Firebase";
import { doc, getDoc } from "firebase/firestore";
import Loading from "../Extra/Loading";
import Logo from "../../Images/Logo.webp";
import "../../Styles/Shop/Cart.css";
import "../../Styles/Navbar.css";
import { FaBars, FaTimes, FaShoppingBag, FaRegUser, FaTrash, FaPlus } from "react-icons/fa";
import { FaRegCreditCard } from "react-icons/fa6";
import { BsFillCartCheckFill } from "react-icons/bs";
import {Pay} from "../API/Payments";
import emailjs from '@emailjs/browser'

const Cart = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [cartProducts, setCartProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const nav = useNavigate();
  const navRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    document.title = 'john rona - Cart'

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    const fetchProducts = async () => {
      try {
        const productsSnapshot = await db
          .collection("Masof")
          .doc(process.env.REACT_APP_DATABASE_MASOF)
          .collection("Websites")
          .doc(process.env.REACT_APP_DATABASE_PROID)
          .collection("Products")
          .get();

        const productsData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setProductsData(productsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
    updateCartCount();

    return () => unsubscribe();
  }, [pathname]);

  useEffect(() => {
    const localStorageCart = JSON.parse(localStorage.getItem('cart')) || [];
    fetchProductsFromFirestore(localStorageCart);
  }, []);

  useEffect(() => {
    const calculateTotalPrice = () => {
      if (cartProducts.length > 0) {
        const symbol = getCurrencySymbol();
        const total = cartProducts.reduce((acc, product) => {
          const price = parseFloat(product[symbol === '₪' ? 'IL' : 'US']);
          return acc + price * product.quantity;
        }, 0);
        setTotalPrice(total);
        localStorage.setItem('totalprice', total)
      } else {
        setTotalPrice(0);
      }
    };

    calculateTotalPrice();
  }, [cartProducts]);

  useEffect(() => {
    const userData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      street,
      postalCode
    };
    localStorage.setItem('userData', JSON.stringify(userData));
  }, [firstName, lastName, email, phoneNumber, street, postalCode]);

  const updateCartCount = () => {
    const existingItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartCount(existingItems.length);
  };

  const fetchProductsFromFirestore = async (cartItems) => {
    try {
      if (cartItems.length === 0) {
        setCartProducts([]);
        setLoading(false);
        return;
      }

      const fetchedProducts = await Promise.all(
        cartItems.map(async ({ productId, quantity }) => {
          const productRef = doc(db, "Masof", process.env.REACT_APP_DATABASE_MASOF, "Websites", process.env.REACT_APP_DATABASE_PROID, "Products", productId);
          const productSnap = await getDoc(productRef);

          if (productSnap.exists()) {
            return { id: productId, quantity, ...productSnap.data() };
          } else {
            console.log("No such document!");
            return null;
          }
        })
      );

      setCartProducts(fetchedProducts.filter(product => product !== null));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  const deleteProduct = async (productId) => {
    try {
      setCartProducts(cartProducts.filter(product => product.id !== productId));

      let existingItems = JSON.parse(localStorage.getItem('cart')) || [];
      existingItems = existingItems.filter(item => item.productId !== productId);
      localStorage.setItem('cart', JSON.stringify(existingItems));
      updateCartCount();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const CheckUserStatusToLogin = () => {
    if (user) {
      nav(`/Profile/${user.uid}`);
    } else {
      nav('/Login');
    }
  };

  const addToLocalStorage = async (productId, quantity = 1) => {
    try {
      let existingItems = JSON.parse(localStorage.getItem('cart')) || [];
      const itemIndex = existingItems.findIndex(item => item.productId === productId);
  
      if (itemIndex !== -1) {
        existingItems[itemIndex].quantity += quantity;
      } else {
        existingItems.push({ productId, quantity });
      }
  
      localStorage.setItem('cart', JSON.stringify(existingItems));
      updateCartCount();
  
      const productRef = doc(db, "Masof", process.env.REACT_APP_DATABASE_MASOF, "Websites", process.env.REACT_APP_DATABASE_PROID, "Products", productId);
      const productSnap = await getDoc(productRef);
  
      if (productSnap.exists()) {
        const productData = { id: productId, quantity, ...productSnap.data() };
        setCartProducts(prevProducts => {
          const existingProductIndex = prevProducts.findIndex(p => p.id === productId);
  
          if (existingProductIndex !== -1) {
            const updatedProducts = [...prevProducts];
            updatedProducts[existingProductIndex].quantity += quantity;
            return updatedProducts;
          } else {
            return [...prevProducts, productData];
          }
        });
        console.log(`Product with ID ${productId} added to cart with quantity ${quantity}!`);
      } else {
        console.error("Product not found");
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };

  const getCurrencySymbol = () => {
    const selectedCountry = JSON.parse(localStorage.getItem('selectedCountry'));
    if (selectedCountry?.value === 'IL') {
      return '₪';
    }
    return '$';
  };

  const isILSelected = () => {
    const selectedCountry = JSON.parse(localStorage.getItem('selectedCountry'));
    return selectedCountry?.value === 'IL';
  };

  const validateForm = () => {
    return (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      email.trim() !== "" &&
      phoneNumber.trim() !== "" &&
      street.trim() !== "" &&
      postalCode.trim() !== ""
    );
  };

  const handlePlaceOrder = async () => {
    if (validateForm()) {
      try {
        const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
  
        if (cartItems.length === 0) {
          alert("Your cart is empty. Please add items to your cart before placing an order.");
          return;
        }
  
        const orderNumber = Math.floor(100000000 + Math.random() * 900000000);
        const userData = JSON.parse(localStorage.getItem("userData")) || {};
        const country = JSON.parse(localStorage.getItem("selectedCountry")) || {};
  
        const customerData = { ...userData, country };
        const orderData = {
          orderNumber: orderNumber,
          items: cartItems,
          paymentStatus: "Pending",
          createdAt: new Date(),
          customer: customerData,
          orderStatus: 'Pending'
        };
  
        await db
          .collection("Masof")
          .doc(process.env.REACT_APP_DATABASE_MASOF)
          .collection("Websites")
          .doc(process.env.REACT_APP_DATABASE_PROID)
          .collection("Orders")
          .doc(orderNumber.toString())
          .set(orderData);
  
        const emailData = {
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          PhoneNumber: phoneNumber,
          Street: street,
          PostalCode: postalCode,
          orderNumber: orderData.orderNumber,
          createdAt: orderData.createdAt.toString(),
          paymentStatus: orderData.paymentStatus
        };
  
        emailjs.send('service_wi1yrcg', 'template_3n86vfr', emailData, 'H6Kj7Mks-mNi98Eab')
          .then((result) => {
            console.log("Admin Email Sent:", result.text);
          }, (error) => {
            console.error("Failed to send email to admin:", error);
          });
  
        emailjs.send('service_wi1yrcg', 'template_lg9dnkx', emailData, 'H6Kj7Mks-mNi98Eab')
          .then((result) => {
            console.log("Customer Email Sent:", result.text);
          }, (error) => {
            console.error("Failed to send email to customer:", error);
          });
  
        localStorage.removeItem("cart");
        localStorage.removeItem("userData");
        localStorage.removeItem("totalprice");

        alert('Done your order has been placed successfully!')
        nav('/')
  
        console.log("Order placed successfully!");
      } catch (error) {
        console.error("Error placing order:", error);
        alert("An error occurred while placing your order. Please try again.");
      }
    } else {
      alert("Please fill in all the required fields and accept the terms.");
    }
  };
  
  
  

  const handleCreditCard = async () => {
    if (validateForm()) {
      try {
        const userData = {
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          PhoneNumber: phoneNumber,
          Street: street,
          PostalCode: postalCode
        };
        localStorage.setItem('userData', JSON.stringify(userData));

        setLoading(true);

        const cartItems = cartProducts.map(product => ({
          id: product.id,
          quantity: product.quantity,
          price: product[getCurrencySymbol() === '₪' ? 'IL' : 'US'],
          Name: product.Name
        }));

        await Pay({ cartItems, userData });

      } catch (error) {
        console.error("Error during payment process:", error);
        alert("There was an error processing your payment. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      alert("Please fill in all the required fields and accept the terms.");
    }
  };

  if (loading) return <Loading />;

  return (
    <React.Fragment>
      <header>
      <a href="/" style={{width: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img
          src={Logo}
          alt="header logo"
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
        />
        </a>
  
        <nav className="navbar" ref={navRef}>
          <div className="innav">
            <img
              src={Logo}
              alt="header logo"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
            />
            <button onClick={showNavbar} className="navbtn navhide">
              <FaTimes color="#3cba99" />
            </button>
          </div>
          <button onClick={() => nav("/Shop")}>Shop</button>
          <button onClick={() => nav("/ChooseCategory")}>Categories</button>
          <button onClick={() => nav("/Contact-us")}>Contact us</button>
          {/*<button style={{ width: "30px" }} onClick={CheckUserStatusToLogin}>
            <FaRegUser />
          </button>*/}
          <button onClick={() => nav("/Cart")} style={{ position: "relative", width: "30px" }}>
            <FaShoppingBag />
            {cartCount > 0 && (
              <span className="cart-count">{cartCount}</span>
            )}
          </button>
        </nav>
        <button onClick={showNavbar} className="navbtn navshow">
          <FaBars color="#3cba99" />
        </button>
      </header>
  
      <div className="MainCart">
        <h1>My items</h1>
      </div>
  
      <div className="Fetch-Products">
        <div className="cart-products">
          {cartProducts.length === 0 ? (
            <p>Your cart is empty</p>
          ) : (
            cartProducts.map((product, index) => (
              <div key={index} className="product-card2">
                <img src={product.Images} alt={product.Name} />
                <p style={{width: '100px'}}>{product.Name}</p>
                <p style={{width: '100px'}}>Price:{getCurrencySymbol()}{product[getCurrencySymbol() === '₪' ? 'IL' : 'US']} </p>
                <p style={{width: '100px'}}> Quantity: {product.quantity}</p>
                <button onClick={() => deleteProduct(product.id)}><FaTrash color="#fff" /></button>
              </div>
            ))
          )}
        </div>
  
        <div className="Payments">
          <div className="Customer-Info">
            <h3>Please fill your information to complete the order :</h3>

            <div className="Info-Inputs">
              <input 
                placeholder="First name" 
                value={firstName} 
                onChange={(e) => setFirstName(e.target.value)} 
                required 
              />
              <input 
                placeholder="Last Name" 
                value={lastName} 
                onChange={(e) => setLastName(e.target.value)} 
                required 
              />
              <input 
                placeholder="E-mail" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
              <input 
                placeholder="Phone Number" 
                value={phoneNumber} 
                onChange={(e) => setPhoneNumber(e.target.value)} 
                required 
              />
              <input 
                placeholder="Street + house number" 
                value={street} 
                onChange={(e) => setStreet(e.target.value)} 
                required 
              />
              <input 
                placeholder="Postal Code" 
                value={postalCode} 
                onChange={(e) => setPostalCode(e.target.value)} 
                required 
              />
            </div>
          </div>
         {/* <h2>Total Price: {getCurrencySymbol()}{totalPrice.toFixed(2)}</h2>

         <div className="Terms">
            <input 
              type="checkbox" 
              checked={termsAccepted} 
              onChange={(e) => setTermsAccepted(e.target.checked)} 
              required 
            />
            <a href="/#">I agree for the terms and policies</a>
          </div>*/}
          <p style={{color: 'red'}}>Please add Products to the cart to (Place your order!)</p>

          <div className="Payments-Btns">
            <button onClick={handlePlaceOrder}><BsFillCartCheckFill /> Place your order</button>
            {/*{isILSelected() && (
              <button onClick={handleCreditCard}><FaRegCreditCard /> כרטיס אשראי</button>
            )}*/}
          </div>
        </div>
      </div>
  
      <section className="MoreProducts">
        <h1>More of our products</h1>
        <div className="MoreProducts-fetch">
          <section className="Sec-3">
            <div className="Sec-3-display">
              {productsData.slice(0, 4).map((product, index) => (
                <div key={index} className="product-card">
                  <img src={product.Images} alt={product.name} />
                  <h3>{product.Name}</h3>
                  
                  <div className="Procardb">
                    <button onClick={() => nav(`/Shop/${product.id}`)}>
                      View Details
                    </button>
                    <button style={{ width: "50px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => addToLocalStorage(product.id, 1)}><FaPlus /></button>
                  </div>
                </div>
              ))}
            </div>
            <button onClick={() => { nav('/Shop') }}>View More</button>
          </section>
        </div>
      </section>

      <section className="Sec-4">

        <img src={Logo} alt="Logo"/>

        <p>Copyright 2024 - johnrona.com</p>
      </section>
    </React.Fragment>
  );
}

export default Cart;
