import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./Extra/Firebase";
import Loading from "./Extra/Loading";
import Logo from "../Images/Logo.webp";
import "../Styles/Navbar.css";
import "../Styles/Home/Sec-1.css";
import "../Styles/Home/Sec-2.css";
import "../Styles/Home/Sec-3.css";
import "../Styles/Home/Sec-4.css";
import { FaBars, FaTimes, FaShoppingBag, FaRegUser, FaPlus } from "react-icons/fa";
import { db } from "./Extra/Firebase";
import MainPopup from "./Extra/Popups/MainPopup";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const [clickedButtons, setClickedButtons] = useState({});
  const [cartCount, setCartCount] = useState(0);
  const nav = useNavigate();
  const navRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    const fetchProducts = async () => {
      try {
        const productsSnapshot = await db
          .collection("Masof")
          .doc(process.env.REACT_APP_DATABASE_MASOF)
          .collection("Websites")
          .doc(process.env.REACT_APP_DATABASE_PROID)
          .collection("Products")
          .get();

        const productsData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setProductsData(productsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        return [];
      }
    };

    fetchProducts();
    updateCartCount();
    return () => unsubscribe();
  }, [pathname]);

  const updateCartCount = () => {
    const existingItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartCount(existingItems.length);
  };

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const CheckUserStatusToLogin = () => {
    if (user) {
      nav(`/Profile/${user.uid}`);
    } else {
      nav("/Login");
    }
  };

  const addToLocalStorage = (productId) => {
    try {
      let existingItems = JSON.parse(localStorage.getItem('cart')) || [];
      
      const itemIndex = existingItems.findIndex(item => item.productId === productId);
  
      if (itemIndex !== -1) {
        existingItems[itemIndex].quantity += 1;
      } else {
        existingItems.push({ productId, quantity: 1 });
      }
  
      localStorage.setItem('cart', JSON.stringify(existingItems));
      updateCartCount();
      console.log(`Product with ID ${productId} added to cart with quantity 1!`);
    } catch (error) {
      console.error('Error updating cart in localStorage:', error);
    }
  };
  

  const handleAddClick = (productId) => {
    addToLocalStorage(productId);
    setClickedButtons((prev) => ({ ...prev, [productId]: true }));

    setTimeout(() => {
      setClickedButtons((prev) => ({ ...prev, [productId]: false }));
    }, 2000);
  };

  if (loading) return <Loading />;

  const getRandomIndex = (excludeIndex) => {
    let randomIndex = Math.floor(Math.random() * productsData.length);
    while (randomIndex === excludeIndex) {
      randomIndex = Math.floor(Math.random() * productsData.length);
    }
    return randomIndex;
  };

  const randomIndex1 = productsData.length > 0 ? getRandomIndex(-1) : -1;
  const randomIndex2 = productsData.length > 0 ? getRandomIndex(randomIndex1) : -1;

  return (
    <React.Fragment>
      <MainPopup/>
      <header>
        <a href="/" style={{width: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img
          src={Logo}
          alt="header logo"
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
        />
        </a>

        <nav className="navbar" ref={navRef}>
          <div className="innav">
            <img
              src={Logo}
              alt="header logo1"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
            />
            <button onClick={showNavbar} className="navbtn navhide">
              <FaTimes color="#3cba99" />
            </button>
          </div>
          <button onClick={() => nav("/Shop")}>Shop</button>
          <button onClick={() => nav("/ChooseCategory")}>Categories</button>
          <button onClick={() => nav("/Contact-us")}>Contact us</button>
          {/*<button style={{ width: "30px" }} onClick={CheckUserStatusToLogin}>
            <FaRegUser />
          </button>*/}
          <button onClick={() => { nav(`/Cart`) }} style={{ position: "relative", width: "30px" }}>
            <FaShoppingBag />
            {cartCount > 0 && (
              <span className="cart-count">{cartCount}</span>
            )}
          </button>
        </nav>
        <button onClick={showNavbar} className="navbtn navshow">
          <FaBars color="#3cba99" />
        </button>
      </header>

      <section className="Sec-1">
        <div>
          <div>
            <h1>John Rona</h1>
            <p>Equip Your Gym with the Best!</p>
            <button onClick={() => nav("/Shop")}>Shop now</button>
          </div>
        </div>
      </section>

      <section className="Sec-3">
        <h1>Shop</h1>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="300"
          height="13"
          viewBox="0 0 538 13"
          fill="none"
        >
          <path
            d="M6 5.5L532 7.5"
            stroke="#3CBA99"
            strokeWidth="11"
            strokeLinecap="round"
          />
        </svg>
        <div className="Sec-3-display">
          {productsData.slice(0, 5).map((product, index) => (
            <div key={index} className="product-card">
              <img src={product.Images} alt={product.name} />
              <h3>{product.Name}</h3>
             
              <div className="Procardb">
                <button onClick={() => nav(`/Shop/${product.id}`)}>
                  View Details
                </button>
                <button
                  style={{ width: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={() => handleAddClick(product.id)}
                >
                  {clickedButtons[product.id] ? '✓' : <FaPlus />}
                </button>
              </div>
            </div>
          ))}
        </div>
        <button onClick={() => { nav('/Shop') }}>All Products</button>
      </section>

      <section className="Sec-2">

        <div className="SB1">

          <p>About us</p>

          <h1>John Rona</h1>

          <p>Elevate your gym with our premium selection of professional-grade fitness equipment, from advanced cardio machines to robust strength training systems, tailored to meet the demands of gym owners who seek to provide their clients with top-tier workout experiences and results</p>

          <button onClick={() => {nav('/Shop')}}>Show now</button>

        </div>
      </section>

      <section className="Sec-4">

        <img src={Logo} alt="Logo"/>

        <p>Copyright 2024 - johnrona.com</p>
      </section>
    </React.Fragment>
  );
};

export default Home;
