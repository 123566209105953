import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../Extra/Firebase";
import Loading from "../Extra/Loading";
import Logo from "../../Images/Logo.webp";
import "../../Styles/Navbar.css";
import '../../Styles/Home/Sec-3.css';
import '../../Styles/Shop/Shop.css';
import { FaBars, FaTimes, FaShoppingBag, FaRegUser, FaPlus } from "react-icons/fa";

const Shop = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [products, setProductsData] = useState([]);
  const [clickedButtons, setClickedButtons] = useState({});
  const [cartCount, setCartCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [categories, setCategories] = useState([]);
  const nav = useNavigate();
  const navRef = useRef();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    document.title = 'john rona - Shop'

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [pathname]);

  useEffect(() => {
    const filterFromUrl = searchParams.get('filter') || '';
    setSelectedFilter(filterFromUrl);
  }, [searchParams]);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const CheckUserStatusToLogin = () => {
    if (user) {
      nav(`/Profile/${user.uid}`);
    } else {
      nav('/Login');
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsSnapshot = await db
          .collection("Masof")
          .doc(process.env.REACT_APP_DATABASE_MASOF)
          .collection("Websites")
          .doc(process.env.REACT_APP_DATABASE_PROID)
          .collection("Products")
          .get();

        const productsData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setProductsData(productsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        return [];
      }
    };

    const fetchCategories = async () => {
      try {
        const categoriesCollection = await db
          .collection("Masof")
          .doc(process.env.REACT_APP_DATABASE_MASOF)
          .collection("Websites")
          .doc(process.env.REACT_APP_DATABASE_PROID)
          .collection("PCategory")
          .get();

        const categoriesList = await Promise.all(categoriesCollection.docs.map(async (categoryDoc) => {
          const categoryData = categoryDoc.data();
          const subCategoriesCollection = await categoryDoc.ref.collection('SubCategories').get();
          const subCategoriesList = subCategoriesCollection.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));

          return {
            id: categoryDoc.id,
            name: categoryData.name,
            subCategories: subCategoriesList,
          };
        }));

        // Flatten categories and subcategories for a unified select list
        const combinedOptions = categoriesList.flatMap(category => 
          [ 
            { id: category.id, name: category.name, type: 'category' },
            ...category.subCategories.map(subCategory => ({
              id: subCategory.id,
              name: `${category.name} - ${subCategory.name}`,
              type: 'subcategory'
            }))
          ]
        );

        setCategories(combinedOptions);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchProducts();
    fetchCategories();
    updateCartCount();
  }, []);

  const updateCartCount = () => {
    const existingItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartCount(existingItems.length);
  };

  const addToLocalStorage = (productId) => {
    try {
      let existingItems = JSON.parse(localStorage.getItem('cart')) || [];
      
      const itemIndex = existingItems.findIndex(item => item.productId === productId);
  
      if (itemIndex !== -1) {
        existingItems[itemIndex].quantity += 1;
      } else {
        existingItems.push({ productId, quantity: 1 });
      }
  
      localStorage.setItem('cart', JSON.stringify(existingItems));
      updateCartCount();
      console.log(`Product with ID ${productId} added to cart with quantity 1!`);
    } catch (error) {
      console.error('Error updating cart in localStorage:', error);
    }
  };

  const handleAddClick = (productId) => {
    addToLocalStorage(productId);
    setClickedButtons((prev) => ({ ...prev, [productId]: true }));

    setTimeout(() => {
      setClickedButtons((prev) => ({ ...prev, [productId]: false }));
    }, 2000);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e) => {
    const newFilter = e.target.value;
    setSelectedFilter(newFilter);
    setSearchParams({ filter: newFilter });
  };

  const filteredProducts = products.filter(product => {
    const matchesCategory = !selectedFilter || 
      product.Category.includes(selectedFilter.split(' - ')[1] || selectedFilter);
    const matchesSearchQuery = product.Name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearchQuery;
  });

  if (loading) return <Loading />;

  return (
    <React.Fragment>
      <header>
        <a href="/" style={{ width: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={Logo}
            alt="header logo"
            onContextMenu={(e) => e.preventDefault()}
            onDragStart={(e) => e.preventDefault()}
          />
        </a>

        <nav className="navbar" ref={navRef}>
          <div className="innav">
            <img
              src={Logo}
              alt="header logo"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
            />
            <button onClick={showNavbar} className="navbtn navhide">
              <FaTimes color="#3cba99" />
            </button>
          </div>
          <button onClick={() => nav("/Shop")}>Shop</button>
          <button onClick={() => nav("/ChooseCategory")}>Categories</button>
          <button onClick={() => nav("/Contact-us")}>Contact us</button>
          {/*<button style={{ width: "30px" }} onClick={CheckUserStatusToLogin}>
            <FaRegUser />
          </button>*/}
          <button onClick={() => nav("/Cart")} style={{ position: "relative", width: "30px" }}>
            <FaShoppingBag />
            {cartCount > 0 && (
              <span className="cart-count">{cartCount}</span>
            )}
          </button>
        </nav>
        <button onClick={showNavbar} className="navbtn navshow">
          <FaBars color="#3cba99" />
        </button>
      </header>

      <section className="Sec-3">
        <div className="Options">
          <h1>Products</h1>
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ marginRight: '10px', padding: '5px' }}
          />

          <button onClick={() => nav('/ChooseCategory')} style={{ padding: '5px', marginRight: '10px' }}>
            {selectedFilter ? `${selectedFilter}` : 'Choose category'}
          </button>
        </div>

        <div className="Sec-3-display">
          {filteredProducts.map((product, index) => (
            <div key={index} className="product-card">
              <img src={product.Images} alt={product.Name} />
              <h3>{product.Name}</h3>
              <div className="Procardb">
                <button onClick={() => nav(`/Shop/${product.id}`)}>
                  View Details
                </button>
                <button
                  style={{ width: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={() => handleAddClick(product.id)}
                >
                  {clickedButtons[product.id] ? '✓' : <FaPlus />}
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="Sec-4">

        <img src={Logo} alt="Logo"/>

        <p>Copyright 2024 - johnrona.com</p>
      </section>
    </React.Fragment>
  );
};

export default Shop;
