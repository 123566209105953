import React, { useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import '../../../Styles/Extra/Popups/MainPopup.css';

const ChooseCountry = ({ fun }) => {
  const options = useMemo(() => countryList().getData(), []);

  const handleCountryChange = (selectedOption) => {
    localStorage.setItem('selectedCountry', JSON.stringify(selectedOption));
    
    fun(selectedOption);
  };

  return (
    <div className='Maind'>
      <Select options={options} onChange={handleCountryChange} />
    </div>
  );
};

export default ChooseCountry;
